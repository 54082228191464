import { Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import PropTypes from 'prop-types';
import React from 'react';
import BlogRoll from './BlogRoll';

function IndexPageTemplate({
  title,
  subheading,
}) {
  return (
    <>
      <div style={{ display: 'grid' }}>
        <StaticImage
          style={{
            gridArea: '1/1',
            maxHeight: 400,
          }}
          layout="fullWidth"
          alt="Girls riding scooters on the Sacramento Tower Bridge, by Merrit Thomas (Unspash)"
          src="../img/pages/unspash-sacramento-tower-bridge-meritt-thomas.jpg"
          formats={['auto', 'webp']}
        />
        <div
          style={{
            gridArea: '1/1',
            position: 'relative',
            placeItems: 'center',
            display: 'grid',
          }}
        >
          <header style={{ margin: '0 20px' }}>
            <h1
              className="has-text-weight-bold is-size-3-mobile is-size-2-tablet is-size-1-widescreen"
              style={{
                boxShadow:
                  'rgb(241, 108, 102) 0.5rem 0px 0px, rgb(241, 108, 102) -0.5rem 0px 0px',
                backgroundColor: 'rgb(241, 108, 102)',
                color: 'white',
                lineHeight: '1',
                padding: '0.25em',
                marginBottom: '20px',
              }}
            >
              {title}
            </h1>
            <h3
              className="has-text-weight-bold is-size-5-mobile is-size-5-tablet is-size-4-widescreen"
              style={{
                boxShadow:
                  'rgb(241, 108, 102) 0.5rem 0px 0px, rgb(241, 108, 102) -0.5rem 0px 0px',
                backgroundColor: 'rgb(241, 108, 102)',
                color: 'white',
                lineHeight: '1',
                padding: '0.25em',
              }}
            >
              {subheading}
            </h3>
          </header>
        </div>
      </div>
      <section className="section section--gradient">
        <div className="container">
          <div className="section">
            <div className="columns">
              <div className="column is-10 is-offset-1">
                <div className="content">
                  <div className="column is-12">
                    <BlogRoll />
                    <div className="column is-12 has-text-centered">
                      <Link className="btn" to="/blog">
                        Read more
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

IndexPageTemplate.propTypes = {
  title: PropTypes.string,
  subheading: PropTypes.string,
}

export default IndexPageTemplate;
